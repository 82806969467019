import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'
import { useMedia } from 'use-media'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'

// Elements
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'
import Plaatjie from '@ubo/plaatjie'

// Images
import ArrowRight from 'img/arrowright.inline.svg'
import ButtonSecondaryTransparent from 'components/elements/Buttons/ButtonSecondaryTransparent'

SwiperCore.use([Autoplay])

interface LatestPostsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_LatestPosts
}

const Wrapper = styled.section`
  & .container {
    @media (max-width: 991px) {
      position: relative;
      padding: 0;
    }
  }
  &.swiper-wrapper {
    transition-timing-function: linear !important;
  }
  & .swiper-container {
    width: 120% !important;
  }
  & .swiper-slide {
    @media (min-width: 992px) {
      width: 75% !important;
    }
    @media (max-width: 991px) {
      width: 40% !important;
    }
  }
`

const Gradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(
    0deg,
    rgba(18, 21, 95, 1) 0%,
    rgba(18, 21, 95, 0) 62%
  );
`

const LatestPosts: React.FC<LatestPostsProps> = ({ fields }) => (
  <Wrapper className="pb-5">
    <Blog fields={fields} />
  </Wrapper>
)

interface BlogProps {
  // eslint-disable-next-line
  fields: any
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpPost,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.latestPostsQueryQuery>(graphql`
    query latestPostsQuery {
      allWpPost(sort: { order: DESC, fields: date }) {
        edges {
          node {
            id
            databaseId
            title
            uri
            recap {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 1400)
                  }
                }
              }
              excerpt
              fieldGroupName
            }
          }
        }
      }
    }
  `)

  const posts: any = allWpPost.edges

  return (
    <BlogBink
      posts={posts as BlogBinkPosts}
      id="latest-Posts"
      limit={Number(fields.amount)}
    >
      <BlogGrid fields={fields} />
    </BlogBink>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_LatestPosts
}

const StyledButtonSecondaryTransparent = styled(ButtonSecondaryTransparent)`
  & a {
    font-weight: ${({ theme }) => theme.font.weight.bold} !important;
  }
`

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const isTablet = useMedia({ maxWidth: 991 })
  const [isHover, setHover] = useState<number | null>(null)

  const blogBink = useBlogBink()

  return (
    <div className="container">
      <ParseContent content={fields.description} className="mb-5 pb-4" />
      {!isTablet ? (
        <div className="row">
          {blogBink.posts.length > 0 &&
            blogBink.posts.map((post, index: number) => {
              const hovered: boolean = index === isHover

              return (
                <div
                  className="col-lg-4 col-sm-6 mb-5"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover(null)}
                >
                  <div className="px-2">
                    <BlogGridPost node={post.node} current={hovered} />
                  </div>
                </div>
              )
            })}
          {blogBink.posts.length < 1 && (
            <div className="text-center">
              <h2 className="mb-4">
                Helaas, we hebben geen artikelen kunnen vinden.
              </h2>
              <div className="mt-5">
                <small>Probeer het later nog eens of</small>
              </div>
              <ButtonSecondaryTransparent to="/succesverhalen">
                Bekijk onze succesverhalen
              </ButtonSecondaryTransparent>
            </div>
          )}
        </div>
      ) : (
        <>
          {blogBink.posts.length > 0 && (
            <Swiper
              freeMode
              loop
              loopFillGroupWithBlank={false}
              slidesPerView="auto"
              spaceBetween={0}
              speed={5000}
              freeModeMomentum={false}
              autoplay={{
                delay: 3,
                disableOnInteraction: true,
              }}
              className="ms-n5"
            >
              {blogBink.posts.map((post, index) => {
                const hovered: boolean = index === isHover

                return (
                  <SwiperSlide
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover(null)}
                    className="w-75 me-4"
                  >
                    <BlogGridPost node={post.node} current={hovered} />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          )}
          {blogBink.posts.length < 1 && (
            <div className="text-center">
              <h2 className="mb-4">
                Helaas, we hebben geen artikelen kunnen vinden.
              </h2>
              <div className="mt-5">
                <small>Probeer het later nog eens of</small>
              </div>
              <ButtonSecondaryTransparent to="/succesverhalen">
                Bekijk onze succesverhalen
              </ButtonSecondaryTransparent>
            </div>
          )}
        </>
      )}

      {blogBink.posts.length > 0 && (
        <div className="row mt-5 mt-lg-0">
          <div className="d-flex justify-content-center">
            <StyledButtonSecondaryTransparent to={fields?.link?.url || '/'}>
              {fields?.link?.title}
            </StyledButtonSecondaryTransparent>
          </div>
        </div>
      )}
    </div>
  )
}

const PostWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};

  & h2 {
    color: ${({ theme }) => theme.color.light};
    font-size: ${({ theme }) => theme.font.size.post};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.medium};
    }
  }

  & span {
    color: ${({ theme }) => theme.color.secondary};
    font-size: ${({ theme }) => theme.font.size.big};
  }

  & svg {
    width: 20px;
    height: 20px;
    & path {
      fill: ${({ theme }) => theme.color.secondary};
    }
  }

  @media (min-width: 575px) {
    box-shadow: 1px 1px 12px 0px #404040;
  }
`

const Image = styled(Plaatjie)`
  @media (max-width: 991px) {
    max-height: 280px;
  }
  @media (max-width: 575px) {
    max-height: 300px;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  node: any
  current: boolean
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, current }) => (
  <PostWrapper>
    <NavLink to={node.uri}>
      <div style={{ overflow: 'hidden' }}>
        <motion.div
          className="position-relative"
          whileHover={{ opacity: 0.75, scale: 1.05 }}
        >
          <Image image={node.recap.image} alt="" />
          <Gradient />
        </motion.div>
      </div>
      <div className="p-lg-5 p-3">
        <h2 className="text-black">{node.title}</h2>
        <NavLink to={node.uri} className="d-flex justify-content-sm-end">
          <span className="me-3">Lees meer</span>
          <motion.span
            animate={current ? { x: 20 } : { x: 0 }}
            transition={{ type: 'spring', stiffness: 100 }}
          >
            <ArrowRight />
          </motion.span>
        </NavLink>
      </div>
    </NavLink>
  </PostWrapper>
)

export default LatestPosts
