import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Elements
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimaryBig from 'components/elements/Buttons/ButtonPrimaryBig'
import BlockDefault from 'components/elements/Blocks/BlockDefault'
import ButtonSecondaryTransparent from 'components/elements/Buttons/ButtonSecondaryTransparent'

interface LatestStoriesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_LatestPosts
}

const Wrapper = styled.div`
  @media (min-width: 992px) {
    min-height: 455px;
  }
`

const PostsWrapper = styled.div`
  position: relative;
  z-index: 4;
`

const Image = styled(Plaatjie)`
  @media (min-width: 992px) {
    height: 550px;
  }
  @media (max-width: 991px) {
    height: 200px;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 100px;
    line-height: 85px;
    text-shadow: 2px 2px 14px rgba(0, 0, 0, 1);
  }
`

const LatestStories: React.FC<LatestStoriesProps> = ({ fields }) => (
  <section className="mb-5 pb-5">
    <Wrapper className="position-relative d-flex h-100 flex-column justify-content-center">
      <div className="d-flex justify-content-center position-relative">
        <Image image={fields.image} alt="" className="position-absolute" />
      </div>
      <div className="container h-100 mb-5">
        <div className="row h-100 align-items-center">
          <Content
            content={fields.description}
            className="position-relative mt-5 pt-lg-5"
          />
        </div>
      </div>
    </Wrapper>
    <Blog fields={fields} />
  </section>
)

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_LatestPosts
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpStory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.latestStoriesQueryQuery>(graphql`
    query latestStoriesQuery {
      allWpStory(sort: { order: DESC, fields: date }) {
        edges {
          node {
            id
            title
            uri
            storydetail {
              description
            }
          }
        }
      }
    }
  `)

  const posts: unknown = allWpStory.edges

  return (
    <BlogBink
      posts={posts as BlogBinkPosts}
      id="latest-Stories"
      limit={Number(fields.amount)}
    >
      <BlogGrid fields={fields} />
    </BlogBink>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_LatestPosts
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <PostsWrapper className="container">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="row">
            {blogBink.posts.map((post) => {
              const { node }: any = post

              return (
                <div key={post.node.id} className="col-md-6 mb-5 d-flex">
                  <BlogGridPost node={node} blogFields={fields} />
                </div>
              )
            })}
            <div className="d-flex justify-content-center pt-md-5">
              <ButtonSecondaryTransparent to={fields?.link?.url || '/'}>
                {fields?.link?.title}
              </ButtonSecondaryTransparent>
            </div>
          </div>
        </div>
      </div>
    </PostsWrapper>
  )
}

const PostContent = styled(ParseContent)`
  & p,
  & span {
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }

  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
`

const Title = styled.h4`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 1rem;
  line-height: 32px;
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_LatestPosts
  node: {
    id: string
    title: string
    uri: string
    // eslint-disable-next-line
    storydetail: GatsbyTypes.WpStory_Storydetail
  }
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => {
  const { storydetail } = node

  return (
    <BlockDefault noHoverEffect className="mx-lg-3 mb-5 mb-md-0 py-5">
      <div className="h-100">
        <div className="h-100 px-lg-5">
          <div className="px-sm-2  h-100">
            <Title className="h-25 text-center">{node.title}</Title>
            <div className="mt-auto h-75">
              <PostContent content={storydetail.description} />
              <div className="d-flex justify-content-end justify-content-sm-center mt-4">
                <ButtonPrimaryBig to={node.uri}>Lees meer</ButtonPrimaryBig>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlockDefault>
  )
}

export default LatestStories
