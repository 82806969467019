import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import ParseContent from 'components/shared/ParseContent'
import FormProfessionals from 'components/flex/Form/FormProfessionals'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimarySmall'
import ButtonPrimaryTransparent from 'components/elements/Buttons/ButtonPrimaryTransparent'

// Images
import Gear from 'img/gear.inline.svg'
import Trencher from 'img/trencher.inline.svg'
import CircleCheck from 'img/circlecheck.inline.svg'

interface LatestProfessionalsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_LatestPosts
}

const Wrapper = styled.section``

const PostsWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
  @media (min-width: 992px) {
    box-shadow: 1px 1px 12px 0px #404040;
  }
`

const Content = styled(ParseContent)`
  & h1 > span:first-child,
  & h2 > span:first-child,
  & h3 > span:first-child,
  & h4 > span:first-child,
  & h5 > span:first-child,
  & h6 > span:first-child {
    font-family: ${({ theme }) => theme.font.family.primary} !important;
    font-weight: ${({ theme }) => theme.font.weight.ultraLight} !important;
  }
`

const LatestProfessionals: React.FC<LatestProfessionalsProps> = ({
  fields,
}) => (
  <Wrapper className="mb-5 pb-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <PostsWrapper className="py-xl-4">
            <div className="py-5 row justify-content-center">
              <div className="col-lg-10">
                <Content
                  content={fields.description}
                  className="mb-lg-4 pb-5"
                />
              </div>
              <Blog fields={fields} />
            </div>
          </PostsWrapper>
        </div>
      </div>
    </div>
  </Wrapper>
)

interface BlogProps {
  // eslint-disable-next-line
  fields: any
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpProfessional,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.latestProfessionalsQueryQuery>(graphql`
    query latestProfessionalsQuery {
      allWpProfessional(sort: { order: DESC, fields: date }) {
        edges {
          node {
            id
            title
            uri
            professionaldetail {
              education
              experience
              skills {
                skill
              }
              skillsseparator
              fieldGroupName
            }
          }
        }
      }
    }
  `)

  const posts: any = allWpProfessional.edges

  return (
    <BlogBink
      posts={posts as BlogBinkPosts}
      id="latest-Professionals"
      limit={Number(fields.amount)}
    >
      <BlogGrid fields={fields} />
    </BlogBink>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_LatestPosts
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <>
      {blogBink.posts.map((post) => {
        const { node }: any = post

        return (
          <div key={post.node.id} className="col-lg-10 d-flex flex-grow- 1mt-5">
            <BlogGridPost node={node} blogFields={fields} />
          </div>
        )
      })}
      <div className="d-flex justify-content-center mt-5 pt-lg-4">
        <ButtonPrimaryTransparent to={fields?.link?.url || '/'}>
          {fields?.link?.title}
        </ButtonPrimaryTransparent>
      </div>
    </>
  )
}

const PostWrapper = styled.div`
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.color.secondary};

  & svg {
    min-height: 25px;
    min-width: 25px;
  }

  & h3 {
    font-size: 20px !important;
  }
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

const Left = styled.div`
  @media (min-width: 992px) {
    width: 63%;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`

const Right = styled.div`
  @media (min-width: 992px) {
    width: 37%;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_LatestPosts
  node: {
    id: string
    title: string
    uri: string
    // eslint-disable-next-line
    professionaldetail: GatsbyTypes.WpProfessional_Professionaldetail
  }
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => {
  const [popupOpen, setPopupOpen] = useState<boolean>(false)

  const { professionaldetail } = node

  const seperator =
    professionaldetail.skillsseparator === 'semicolon' ? ';' : ','

  return (
    <div className="d-flex flex-lg-column flex-row w-100">
      <PostWrapper className="px-lg-2 py-lg-4 px-1 py-4">
        <div className="d-flex flex-column flex-sm-row justify-content-between mb-4">
          <h3>{node.title}</h3>
          <StyledButtonPrimary isCustom to={node.uri} className=" mt-2 mt-sm-0">
            <button onClick={() => setPopupOpen(true)} type="button">
              Meer informatie
            </button>
          </StyledButtonPrimary>
        </div>
        <div className="d-flex flex-column flex-lg-row pr-lg-3 ">
          <Left className="d-flex flex-column flex-lg-row">
            <div className="d-flex mb-4 mb-lg-0">
              <Gear />
              <span className="ps-2">
                {professionaldetail.skills?.map((skill, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={index}>{`${skill?.skill}${seperator} `}</span>
                ))}
              </span>
            </div>
          </Left>
          <Right className="d-flex justify-content-lg-end flex-column flex-lg-row">
            <div className="d-flex flex-column flex-lg-row px-lg-5 ">
              <div className="d-flex mb-4 mb-lg-0">
                <Trencher />
                <span className="ps-2">{professionaldetail.education}</span>
              </div>
            </div>
            <div className="d-flex flex-column flex-lg-row pl-lg-5">
              <div className="d-flex mb-4 mb-lg-0">
                <CircleCheck />
                <span className="ps-2">{`${professionaldetail.experience} jaar ervaring`}</span>
              </div>
            </div>
          </Right>
        </div>
      </PostWrapper>
      <FormProfessionals
        isOpen={popupOpen}
        title={node.title}
        requestClose={() => setPopupOpen(false)}
        blogFields={blogFields}
      />
    </div>
  )
}

export default LatestProfessionals
